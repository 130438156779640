@import "./carouselOverrides";
@import "mixins";

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


html, body {
	height: 100%;
}

body, #root {
	@include flexbox();
	min-height: 100vh;
	@include flex-direction(column);
}
