@import "../../core/mixins";
@import "../../core/colors";

.circleContainer {
	@include clearfix();
	margin-left: auto;
	margin-right: auto;
}

%circle-action {
	@include avatar(25px);
	border: none;
	float: left;

	 i {
		 font-size: 13px;
		 color: white;
		 padding-top: 6px;
	 }

	div {
		padding-top: 3px;
		color: white;
		font-weight: bold;
	}
}

.selected {
	& div {
		font-weight: bold;
	}
}

.redCircle {
	@extend %circle-action;
	background-color: $logoRed;
}

.blueCircle {
	@extend %circle-action;
	background-color: $logoBlue;
}

.orangeCircle {
	@extend %circle-action;
	background-color: $logoOrange;
}

.greenCircle {
	@extend %circle-action;
	background-color: $logoGreen;
}

.yellowCircle {
	@extend %circle-action;
	background-color: $logoYellow;
}

.circleLabel {
	margin-left:10px;
	margin-top: 3px;
	float: left;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

