@import "../../core/mixins";

.container {
	width: 100%;
	padding: 10px 0;
	text-align: right;
}

.logo {
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
	background-image: url(./assets/logo.png);
	@include image-2x('./assets/logo@2x.png');
	transition: background-size 2s ease-in;
	-moz-transition: background-size 2s ease-in;
	-ms-transition: background-size 2s ease-in;
	-o-transition: background-size 2s ease-in;
	-webkit-transition: background-size 2s ease-in;

	&.small {
		width: 300px;
		height: 120px;
		background-size: 300px 120px;
	}

	&.large {
		background-size: 443px 177px;
		width: 443px;
		height: 177px;
	}
}

.heading {
	font-size: 36px;
	text-align: center;
	font-weight: 200;
	padding: 0;
	margin: 10px 0;
}
