@import '~react-responsive-carousel/lib/styles/carousel.css';

$triangleSize: 20px;
// Remove the dots on the images
.control-dots {
	display: none;
}

//
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
	margin: 0 5px;
	display: inline-block;
	border-top: $triangleSize solid transparent;
	border-bottom: $triangleSize solid transparent;
	content: '';
}

.carousel .control-prev.control-arrow:before {
	border-right: $triangleSize solid #fff;
}

.carousel .control-next.control-arrow:before {
	border-left: $triangleSize solid #fff;
}

.carousel.carousel-slider .control-arrow:hover {
	background: none;
}
