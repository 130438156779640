$primary: #282c34;


$primaryText: #262626;
$navigationLink: #1E1E1E;

$secondaryAccent: #F2F2F2;

$white: #FFF;
$nearBlack: #181818;


$logoRed: #C7242B;
$logoYellow: #EECA4B;
$logoGreen: #6EBE96;
$logoBlue: #35BCE1;
$logoOrange: #E06E2B;
