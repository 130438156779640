@import 'mixinLibraries/flexbox';

@mixin image-2x($image) {
	@media (min--moz-device-pixel-ratio: 1.3),
	(-o-min-device-pixel-ratio: 2.6/2),
	(-webkit-min-device-pixel-ratio: 1.3),
	(min-device-pixel-ratio: 1.3),
	(min-resolution: 1.3dppx) {
		background-image: url($image);
	}
}


@mixin avatar($size) {
	width: $size;
	height: $size;
	border: 1px solid #979797;
	background-color: white;
	text-align: center;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;

	.initials {
		position: relative;
		top: $size*0.25;
		font-size: $size*0.5;
		line-height: $size*0.5;
		color: black;
	}
}


@mixin clearfix() {
	content: "";
	display: table;
	clear: both;
}
