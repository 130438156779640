@import '../../../core/colors';
@import '../../../core/mixins';

.section {
	width: 100%;
	margin-top: 30px;
}

.buttonContainer {
	margin-left: auto;
	margin-right: auto;
}

.container {
	margin: 0 auto;
	width: 100%;
}

.fundraisers {
	margin-top: 15px;
	font-size: 16px;
	width: 100%;
	text-align: right;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.navigationContainer {
	margin-top: 55px;
}
