@import "../../core/colors";

.linkList {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		&:after {
			color: $primaryText;
			content: "\2022";
			padding: 0 10px;
		}
		&:last-child:after {
			content: "";
			padding: 0;
		}

		display: inline-block;
	}

	a {
		color: $navigationLink;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}


