@import '../../core/colors';
@import '../../core/mixins';

.carouselContainer {
	position: relative;
	width: 100%;
}

.legend {
	font-size: 28px;
	padding: 8px 0;
	background-color:rgba(255,255,255,.9);
	width: 100%;
	font-color: $nearBlack;
	position: absolute;
	bottom: 20px;
	transition: opacity 0.35s ease-in-out;
	text-align: center;
}

.legendLabel {
	width: 380px;
	text-align: right;
	float:left;
}
.donate {
	width: 346px;
	float: right;
	text-align: left;
	color: $logoGreen;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.circle {
	@include avatar(100px);
	position: absolute;
	left: 395px;
	bottom: -15px;
}
