@import "../../core/mixins";
@import "../../core/colors";

.button {
	cursor: pointer;
	@include clearfix();
	.plus {
		float: left;
		@include avatar(100px);
		background-color: $logoGreen;
		border: none;

		.plusSign {
			position: relative;
			top: 5px;
			font-size: 75px;
			line-height: 75px;
			color: white;
		}

		margin-right: 15px;
	}

	.text {
		float: left;
		text-align: center;
		font-size: 80px;
		font-style: italic;
		font-weight: 200;
	}
}
